import React, { useEffect, useState } from "react";
import Navbar from "../components/Discover/Navbar";
import BASEURL from "../helpers/BASEURL";
import UpButton from "../components/Discover/UpButton";
import Container from "../components/Discover/Container";
import { Link } from "react-router-dom";
import Exhibition from "../components/Discover/Exhibition";
import Fonts from "../Fonts/fonts";

function Discover() {
  const [exhibitions, setExhibitions] = useState([]);

  useEffect(() => {
    async function _fetchExhibitions() {
      const res = await fetch(BASEURL + "exhibitions");
      const json = await res.json();
      setExhibitions(json.results);
    }
    _fetchExhibitions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Fonts />
      <Navbar />
      <Container>
        {exhibitions.map((exhibition) => (
          <Link
            key={exhibition.exhibition_id}
            to={{
              pathname: "exhibitions/" + exhibition.exhibition_id,
              state: { exhibition },
            }}
          >
            <Exhibition {...exhibition} />
          </Link>
        ))}
      </Container>
      <UpButton />
    </div>
  );
}

export default Discover;
