import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./components/notFound";
import App from "./App";
import Discover from "./pages/Discover";
import artwork from "./components/artwork";
import ArtGallery from "./components/Discover/ArtGallery";

export default () => (
  <Switch>
    <Route path="/discover" component={Discover} />
    <Route path="/exhibitions/:exhibitionId" component={ArtGallery} />
    <Route path="/:artid" component={artwork} />
    <Route exact path="/" component={App} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
