import React, { useEffect, useState } from "react";
import Navbar from "../Discover/Navbar";
import BASEURL from "../../helpers/BASEURL";
import Grid from "../Discover/Grid";
import Artwork from "../Discover/Artwork";
import UpButton from "../Discover/UpButton";

function ArtGallery(props) {
  const { exhibitionId } = props.match.params;
  const { exhibition } = props.location.state;
  const [artworks, setArtworks] = useState([]);

  useEffect(() => {
    async function _fetchArtworks() {
      const res = await fetch(BASEURL + `exhibitions/${exhibitionId}`);
      const json = await res.json();
      setArtworks(json);
    }
    _fetchArtworks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Navbar />
      <h2>{exhibition.name}</h2>
      <Grid>
        {artworks.map((art) => (
          <Artwork key={art.artwork_id} {...art} />
        ))}
      </Grid>
      <UpButton />
    </div>
  );
}

export default ArtGallery;
