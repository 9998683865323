import React, { Component } from 'react'
import QrReader from 'react-qr-scanner'
 
// Make sure to do an `npm i react-qr-scanner`.

class QrScanner extends Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 100,
      showbutton:false,
      result: "Scanning..",
    }
 
    this.handleScan = this.handleScan.bind(this);
    this.scantoartwork = this.props.scantoartwork;
  }
  
  handleScan(data){
    if(data!=null){
      var artworkid = data.split('/');
      this.setState({
        result: artworkid[artworkid.length-1]
      })
      this.setState({'showbutton':true});
      this.scantoartwork(this.state.result);
      var button = document.getElementById("artworkButton");
      button.click();
    }
  }

  componentDidMount(){

  }
  
  handleError(err){
    console.error(err)
  }
  
  render(){
    const previewStyle = {
      height: 240,
      width: 320,
    }
 
    return(
      <div className="qrScanner text-center">
        <h3 className="pt-4">Please scan a QR code</h3>
        <p>Point your camera towards a qr code and it will immediately scan!</p>
          <QrReader
            delay={this.state.delay}
            style={previewStyle}
            onError={this.handleError}
            onScan={this.handleScan} />
        <div>{this.state.showbutton ?<button id="artworkButton" className="d-block btn btn-primary mx-auto my-3" onClick={this.props.action}>Go to artwork</button>:null}</div>
      </div>
    )
  }
}

export default QrScanner;