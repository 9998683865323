import React from "react";
import styled from "styled-components";

const Section = styled.section`
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  & a {
    color: black;
    &:hover {
      text-decoration: none;
    }
  }
`;
function Container(props) {
  return <Section>{props.children}</Section>;
}

export default Container;
