// Import the React and ReactDOM libraries
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify";
import config from "./config";
import Routes from "../src/routes";
// Configure Amplify for authentication by exhibition ID
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [],
  },
});

// Take the React App Component as a starter and show it on the screen
//ReactDOM.render( <App />, document.querySelector('#root'));
ReactDOM.render(
  <Router>
    <Routes />
  </Router>,
  document.getElementById("root")
);
