import React, { Component } from "react";

import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

class SplashScreen extends Component {
  constructor(props) {
    super(props);
    document.cookie = "isVisible=1";
    this.state = {
      email: "info@futurecoders.org.uk",
      password: "",
    };
  }

  // check that there is an email address and a password - authentication to be added later this is for authentication
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  //for authentication - amend later
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  //Toggle logo and caption with scan button - NOT IN USE
  handleSubmit = async (event) => {
    event.preventDefault();
    var x = this.state.password;
    if (x === "Duende") {
      let elementlist = ReactDOM.findDOMNode(this).getElementsByClassName(
        "toggle"
      );
      for (var i = 0; i < elementlist.length; i++) {
        elementlist[i].style.visibility = "visible";
      }
      elementlist = ReactDOM.findDOMNode(this).getElementsByClassName(
        "toggleop"
      );
      for (i = 0; i < elementlist.length; i++) {
        elementlist[i].style.visibility = "hidden";
      }
    }

    /*try {
        await Auth.signIn(this.state.email, this.state.password);
        alert("Logged in");
      } catch (e) {
        alert(e.message);
      }*/
  };

  render() {
    return (
      <div className="splashscreen bg-white">
        {/* Splashscheen Header */}
        <div className="text-center">
          <div className="position-relative">
            <img
              className="splashscreen_image"
              src="/images/aaww-jpg-cropped.jpg"
              alt="AWW Logo"
            />
            <svg
              className="splashscreen_imageCurve"
              viewBox="0 0 1440 65"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 59.071c480.233-78.761 960.233-78.761 1440 0v4.996H0V59.07z"
                fill="#FFF"
                fillRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>

        {/* Main body */}
        <div className="splashscreen_body px-2 text-center pt-3 bg-white">
          <h1>ArtWorkingWords</h1>
          <p>Creating a conversation between you and the artist.</p>
          <div className="btn btn-primary cursor-pointer">
            <button
              className="btn btn-primary cursor-pointer text-white"
              onClick={this.props.action}
            >
              Scan artwork
            </button>
          </div>
          <Link to="discover">
            <div className="btn btn-primary cursor-pointer m-2">
              <button className="btn btn-primary cursor-pointer text-white">
                Discover artwork
              </button>
            </div>
          </Link>
        </div>

        {/* Footer (Not in use) */}
        <div className="text-center px-1 mx-auto d-block bg-white">
          <img
            className="splashscreen_footer_logo"
            src="/images/fc_icon.png"
            alt="futureCoders logo"
          />
          <p>
            This app has been created by a team of young developers gaining work
            experience
          </p>
        </div>
      </div>
    );
  }
}

export default SplashScreen;
