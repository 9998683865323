import React from "react";
import styled from "styled-components";
import smoke from "../../images/smoke.jpg";

const Div = styled.div`
  position: relative;
  display: flex;
  background: url(${smoke});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center bottom;
  padding: 15px;
  margin: 15px;
  border-radius: 10px;
  box-shadow: 0 15px 40px #00000055;
  transition: all 0.5s;
  text-align: center;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 40px #00000070;
  }
  @media (max-width: 740px) {
    flex-direction: column;
  }
`;

const Text = styled.p`
  font-family: "Painting With Chocolate";
  font-size: 80px;
  background: rgb(115, 0, 193);
  background: linear-gradient(
    0deg,
    rgba(115, 0, 193, 1) 1%,
    rgba(135, 40, 199, 1) 29%,
    rgba(136, 50, 194, 1) 51%,
    rgba(253, 29, 29, 1) 100%
  );
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  text-shadow: 0px 4px 4px #00000040, 0px 4px 4px #00000040,
    0px 4px 4px #00000040;

  @media (max-width: 740px) {
    font-size: 1.5em;
  }
`;

const FromTo = styled(Text)`
  font-size: 25px;
  margin: 0;
`;

const Dates = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  & .dates {
    font-family: "Painting With Chocolate";
    font-size: 30px;
  }
`;
const Exh = styled.div`
  display: flex;
  flex: 4;
  justify-content: center;
  align-items: center;
`;
const Venue = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;

  & p {
    font-size: 30px;
  }
`;

function Exhibition({ name }) {
  return (
    <Div>
      <p>{name}</p>
    </Div>
  );
}

export default Exhibition;
