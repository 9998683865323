// Import the React and ReactDOM libraries
import React, {Component} from 'react'
//import Routes from "./routes";
import './styles/app.scss';
import SplashScreen from './components/splashscreen';
import Qrscanner from './components/qr-scanner/qrscanner';
import Artwork from './components/artwork';
//import Confirmation from './components/confirmation'

class App extends Component {

   
   constructor() {
      super();
      //this.updateArtwork = this.updateArtwork.bind(this);
      // Bind the this context to the handler function
      this.updateView = this.updateView.bind(this);
      this.state = {
         views:["splashscreen","qrscanner","artwork","confirmation"],
         view:0,
         artworkId:0,
         audioSource:"",
         artistImage:""
      };
      // this.confirmresponse = this.confirmresponse.bind(this);
    }

    updateView(){
       if(this.state.view === this.state.views.length){
          this.setState({view:0});
       }
       else{
          var nextview = this.state.view + 1;
          this.setState({view:nextview});
       }
      nextview = this.state.view + 1;
    }

    scantoartwork(artworkid){
      this.setState({artworkId:artworkid});
    }

   //  confirmresponse(audiosrc, artistpic){
   //     this.setState({audioSource:audiosrc});
   //     this.setState({artistImage:artistpic});
   //  }

   render() {
      var scantoartwork = this.scantoartwork;
      //var confirmresponse = this.confirmresponse;

      if(this.state.views[this.state.view] === "splashscreen"){
         return (
            <div className = "appContainer">
               <SplashScreen action={this.updateView}></SplashScreen>
            </div>
         )
      }
      else if(this.state.views[this.state.view] === "qrscanner"){
         return (
            <div className = "appContainer">
               <Qrscanner action={this.updateView} scantoartwork = {scantoartwork.bind(this)}></Qrscanner>
            </div>
         )
      }
      else if(this.state.views[this.state.view] === "artwork"){
         return (
            <div className = "appContainer">
               {/* <Artwork action={this.updateView} artid={this.state.artworkId} confirmresponse={confirmresponse.bind(this)}></Artwork> */}
               <Artwork action={this.updateView} artid={this.state.artworkId}></Artwork>
            </div>
         )
      }
      // else if(this.state.views[this.state.view] === "confirmation"){
      //    return (
      //       <div className = "appContainer">
      //          <Confirmation audiosrc={this.state.audioSource} artistpic={this.state.artistImage} ></Confirmation>
      //       </div>
      //    )
      // }
   }
}

export default App;