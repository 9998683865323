import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Recorder from 'recorder-js';

class Audiorecord extends Component {
    
    constructor(props) {
        super(props);
    
        this.state = {
            showbutton:false,
            recording:false,
            submitted:false,
            timeleft:0,
            timer:null,
            interval:null,
            audiofileURL:"",
            sending:false
        };

        this.handleRecordClick = this.handleRecordClick.bind(this);
        this.confirmdata = this.props.confirmdata;
    }

    // delay display of record button to allow user to listen to artist sound first - ADD feature to show on stop audio
    componentDidMount(){
        this.setState({ showbutton: false });
        this.startTimer();
        ReactDOM.findDOMNode(this).addEventListener('record-click', this.handleRecordClick);
    }

    componentWillUnmount(){
        if(this.state.timer){
            clearTimeout(this.timer);
        }
        if(this.state.interval){
            clearInterval(this.state.interval);
        }
    }

     // timer to delay display of record button start on load
    startTimer() {
        this.setState({
            timer:setTimeout(() => {
                this.setState({ showbutton: true});
            },4000)});
    }

    // prepare for uploading to s3 as WAV
    convertBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
            // Convert the blob to a base64 representation            
            var base64AudioData;
            var reader = new window.FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                base64AudioData = (reader.result);
                base64AudioData = base64AudioData.split(',')[1];
                resolve(base64AudioData);            
            };
        });        
    }

    // toggle buttons and send data to APIs
    stopRecording(recorder, artworkid) {
        this.setState({recording:false});
        recorder.stop().then(({blob}) => {
            this.createAwsAudioFile(artworkid, blob);
            //recorder.clear(); - documentation says it exists
        });
    }
    
    // put sound file and record its URL and artworkid in database
    createAwsAudioFile(artworkId, blob) {
        this.setState({sending:true});
        this.setState({showbutton:false});
        this.convertBlobToBase64(blob).then((base64) => {
            var bodyJson = JSON.stringify({
                "artworkId": artworkId,
                "filedata": base64
            });
            // add error handling
            fetch('https://cors-anywhere.herokuapp.com/https://futurecodersse.pythonanywhere.com/api/respond/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: bodyJson
            })
            .then(response => response.json())
            .then(data => {
                this.setState({submitted:true});
                this.confirmdata(data.body.filename, this.state.submitted);
            })
            .catch(error => {
                console.log(error);
            });
        });
    }

    // set up a new recorder, ask for permission first time only
    handleRecordClick(){
        var constraints = { audio: true };
        var artworkid = this.props.artwork;
        var responder = ReactDOM.findDOMNode(this);
        var audioContext =  new (window.AudioContext || window.webkitAudioContext)();
        var recorder = new Recorder(audioContext);
    
        if(!this.state.recording){
            this.setState({recording:true});
            navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                recorder.init(stream);
                recorder.start();
                this.setState({ timeleft: 30});  
                // start a countdown and stop recording when it gets to 0 if not clicked            
                responder.onclick = () => {
                     if (recorder.audioContext.state === 'running') {
                        this.stopRecording(recorder, artworkid);
                        //recorder.clear(); - documentation says it exists
                    } 
                };
            });
        }
    }
    
    render(){
        if(!this.state.sending){
            return(
                <div>
                    <button ref="record" className={this.state.showbutton ? "btn btn-primary cursor-pointer" : "d-none"}  onClick={this.handleRecordClick}> {this.state.recording ? "Tap to stop recording. " + this.state.timeleft + " seconds allowed." : this.state.submitted ? "Thank you for sharing your thoughts" : "Please record up to 30 seconds of your thoughts"}</button>
                </div>
            )
        }
        else{
            return (
                <div className="text-center w-100">
                    {/* HTML for the loading animation*/}
                    <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )
        }
    }
}

export default Audiorecord;
