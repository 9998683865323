export default {
    s3: {
      REGION: "eu-west-2",
      BUCKET: "artworkingwordsapp"
    },
    apiGateway: {
      REGION: "eu-west-2",
      URL: ""
    },
    cognito: {
      REGION: "eu-west-2",
      USER_POOL_ID: "eu-west-2_otSiy4GeJ",
      APP_CLIENT_ID: "71ln8ii7s7oqtu2cj6ptfjk6c2",
      IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
    }
  };