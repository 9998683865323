import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const Up = styled.button`
  position: fixed;
  bottom: 10px;
  right: 10px;
  outline: none;
  border: none;
  background-color: #442697;
  color: #ced4da;
  border-radius: 50%;
  font-size: 35px;
  height: 50px;
  opacity: 0;
  width: 50px;
  transform: rotate(90deg);
  transition: all 0.2s;
  @media (min-width: 1200px) {
    bottom: 10px;
    right: calc(100vw / 2 - 650px);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    bottom: 2.2%;
    box-shadow: 0 10px 20px #00000033;
  }
  &:active {
    bottom: 1.9%;
    box-shadow: 0 5px 10px #00000033;
  }
`;
function UpButton() {
  const scrollY = useRef(0);
  const element = useRef(null);

  const handleScroll = () => {
    scrollY.current = window.scrollY;
    element.current.style.opacity = scrollY.current * 0.005;
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Up ref={element} onClick={scrollTop}>
      &#10094;
    </Up>
  );
}

export default UpButton;
