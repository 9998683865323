import React, { Component } from "react";
import Audiorecord from "./audiorecord.js"; // new audio recording component
import BASEURL from "../helpers/BASEURL.js";
import Confirmation from '../components/confirmation' 

class Artwork extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      artistdata: null,
      error: false,
      isLoading: false,
      showButton: false,
      hasWebsite: false,
      audioSource: "",
      artistImage: "",
      submitted: false,
    };
    this.artID = this.props.artid || this.props.match.params.artid;
    // this.confirmresponse = this.props.confirmresponse;
  }

  confirmdata(audiosrc, submitted) {
    this.setState({ audioSource: audiosrc });
    this.setState({ submitted: submitted });
  }

  getData() {
    if (this.artID) {
      fetch(BASEURL + "artworks/" + this.artID)
        .then((response) => response.json())
        .then((data) => {
          this.setState({ data: data, isLoading: false });
          fetch(BASEURL + "artists/" + data.artist_id)
          .then((response) => response.json())
          .then((data => {
            this.setState({artistdata:data});
            this.setState({ hasWebsite: data.website_url !== "" ? true : false });
            this.setState({ artistImage: data.artist_image_url });            
          }))
          .catch((error) => this.setState({error, isLoading:false}))
        })
        .catch((error) => this.setState({ error, isLoading: false }));
    }
  }

  startTimer() {
    setTimeout(() => {
      this.setState({ showButton: true });
    }, 47000);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.setState({ artworkId: this.artID });
    this.getData();
    this.startTimer();
  }

  //Recording functions

  render() {
    var confirmdata = this.confirmdata;
    if (this.state.isLoading) {
      return (
        <div className="text-center w-100 py-5">
          <p className="pt-5 pb-3">Loading </p>
          {/* HTML for the loading animation*/}
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }

    if (this.state.data && !this.state.error && !this.state.submitted) {
      return (
        <div
          className="artworkPage"
          id="container"
          style={{ backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
        >
          <div className="artworkPage_artwork text-center">
            <img
              alt="artwork"
              id="artiImgHolder"
              className="w-100"
              src={
                this.state.data
                  ? this.state.data.image_url
                  : "/images/duende.jpg"
              }
              width="100"
            ></img>
            <h3 className="pt-3 pb-0 mb-0" id="artName">
              {this.state.data ? this.state.data.name : "Artwork Name"}
            </h3>
            <p className="mt-0">
              {" "}
              {this.state.artistdata
                ? "by " +
                  this.state.artistdata.first_name +
                  " " +
                  this.state.artistdata.surname
                : ""}
            </p>
            <p className="mt-0">
              {this.state.hasWebsite ? (
                <a
                  className="text-black"
                  href={this.state.artistdata.website_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See more work by this artist
                </a>
              ) : null}{" "}
            </p>
            <audio
              href="#audioURL"
              id="audioHolder"
              controls
              type="mp3"
              src={this.state.data ? this.state.data.audio_url : ""}
            ></audio>
            <p id="mediaHolder">
              {this.state.data ? this.state.data.media : "Unknown media"}
            </p>
            <p id="question">
              <strong>Question: </strong>
              {this.state.data
                ? this.state.data.question
                : "How does this artwork make you feel?"}
            </p>
            <Audiorecord
              artwork={this.state.data.artwork_id}
              confirmdata={confirmdata.bind(this)}
            ></Audiorecord>
            <button
              className={
                this.state.submitted
                  ? "btn btn-primary cursor-pointer text-white"
                  : "d-none"
              }
              onClick={this.props.action}
            >
              Listen to your recording and scan another artwork
            </button>
          </div>
        </div>
      );
    } else if(this.state.submitted){
        return (
          <div className = "appContainer">
            <Confirmation audiosrc={this.state.audioSource} artistpic={this.state.artistImage} ></Confirmation>
          </div>
      )
    }
    else {
      return (
        <div className="text-center p-4">
          <h1> :( </h1>
          <p>
            There's been an error - please try scanning another artwork or try
            again later
          </p>
          <button className="btn btn-primary text-white">
            <a href="/">Start again</a>
          </button>
        </div>
      );
    }
  }
}

export default Artwork;
