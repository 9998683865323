import React from "react";
import styled from "styled-components";

const Container = styled.section`
  width: 100%;
  max-width: 1200px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  &:hover div:not(:hover) {
    opacity: 0.5;
    transform: scale(0.95);
    transition: all 1s;
  }
`;
function Grid(props) {
  return <Container>{props.children}</Container>;
}

export default Grid;
