import React, { Component } from 'react';

class Confirmation extends Component {
    constructor(props) {
      super(props);

      // import the url of the file on s3 for the audio player
      this.state = {
          audiosrc:this.props.audiosrc,
          artistpic:this.props.artistpic
      };
    }

    componentDidMount(){
        var audioURL = "https://aww-soundfile-store.s3.eu-west-2.amazonaws.com/" + this.props.audiosrc;
        this.setState({ audiosrc: audioURL });
    }


    render() {
      return (
        <div className="artworkPage" id="container" style={{backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
          <div className="artworkPage_artwork text-center">
              <img alt="artwork" id="artiImgHolder" className="w-100" src={this.state.artistpic} width="100"></img>
              <p>Your sound file has been submitted.  Have a listen to what you said.</p>
              <div>
                <audio controls>
                    <source src={this.state.audiosrc} type="audio/ogg" />
                    <source src={this.state.audiosrc} type="audio/wav" />
                    Your browser does not support audio
                </audio>
              </div>

              <div>
                <button className="btn btn-primary cursor-pointer"><a className="text-white" href="/">Scan more artwork</a></button>
              </div>
          </div>
        </div>
      );
    }
  }
  
  export default Confirmation;