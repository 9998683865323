import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
const animate = keyframes`
  from {
    width: 0;
  }
  
  to {
    width: 100%;
  }
  `;

const Nav = styled.nav`
  position: fixed;
  display: flex;
  height: 75px;
  width: 100vw;
  background: #442697;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  & img {
    height: 100%;
  }

  & a {
    color: #fff;
    font-size: 25px;
    margin-right: 20px;
    text-decoration: none;
    position: relative;
    padding: 5px;
    @media (max-width: 740px) {
        font-size: 15px;
        margin-right: 0;
      }

    &:hover {
      color: whitesmoke;
      text-decoration: none;
      &::before {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 7px;
        width: 0px;
        content: "";
        background: #c7b8f2;
        animation: ${animate} 500ms forwards;
      }
    
    }
    & span {
      display: inline-block;
      transform: rotateY(180deg);
      }
    }
  }

  & h2 {
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    @media (max-width: 740px) {
      font-size: 30px;
    }
  }
`;

function Navbar() {
  return (
    <Nav>
      <img src="../images/aaww-jpg-cropped.jpg" alt="app logo" />
      <h2>Discover Artwork</h2>
      <Link to="/">
        <span>&#10132;</span> Back
      </Link>
    </Nav>
  );
}

export default Navbar;
