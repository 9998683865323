import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const Div = styled.div`
  position: relative;
  display: flex;
  width: 31.6%;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 15px 40px #00000055;
  transition: all 0.5s;
  overflow: hidden;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
    box-shadow: 0 15px 40px #00000070;

    & section {
      opacity: 1;
      height: 50px;
    }
    & p {
      opacity: 1;
      height: 30px;
    }
  }
  & a > img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  @media (max-width: 1200px) {
    width: 30.6%;
  }

  @media (max-width: 740px) {
    width: 100%;
  }
`;

const Info = styled.section`
  position: absolute;
  width: 100%;
  background-color: #00000070;
  bottom: 0;
  height: 0;
  opacity: 0;
  transition: all 1.5s;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Question = styled.p`
  position: absolute;
  width: 100%;
  font-weight: bold;
  background-color: #00000070;
  text-align: center;
  height: 0;
  opacity: 0;
  color: white;
  transition: all 1.5s;
`;

const Audio = styled.audio`
  width: 40%;
  height: 25px;
  outline: none;
`;

function Artwork({ image_url, question, audio_url, artwork_id }) {
  return (
    <Div>
      <Link to={'/' + artwork_id}>
        <Question>{question}</Question>
        <img src={image_url} alt={`artwork with question ${question}`} />
        <Info classname="info">
          <Audio controls>
            <source src={audio_url} type="audio/wav" />
            Your browser does not support the audio tag.
          </Audio>
        </Info>
      </Link>
    </Div>
  );
}

export default Artwork;
